import { useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import "./DeleteAccountRequest.css";
import { config } from "./config";
import axios from "axios";

export async function deleteAccountLoader({ params }: any) {
  return { team: params.team };
}

export const DeleteAccotuntRequest = () => {
  const captchaRef = useRef(null);
  const { team } = useLoaderData() as { team: string };
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const submitForm = () => {
    // @ts-ignore
    const token = captchaRef.current.getValue();

    if (!email || !token) {
      setError("Please fill out the details correctly below");
      return;
    }

    axios.post(`https://api.fbna.co.uk/api/v1/${team}/deleteAccount`, {
      token,
      email,
    });
    // @ts-ignore
    captchaRef.current.reset();
    setError("");
    setIsSubmitted(true);
  };

  // @ts-ignore
  const teamConfig = config[team];
  console.log(teamConfig);

  const linearGradient = `linear-gradient(to bottom, ${teamConfig.primary}, ${teamConfig.primaryGradient})`;

  return (
    <div
      style={{
        background: teamConfig.primary,
        color: teamConfig.color,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="header" style={{ background: linearGradient }}>
        <h1>{teamConfig.name}</h1>
      </div>
      <div className="container">
        <h2>Delete Account</h2>

        {isSubmitted && !error ? (
          <p>Account deletion request submitted.</p>
        ) : (
          <>
            <p>
              To delete your account for this app, it is recommended to delete
              the app from your phone first and then fill out the data below.
            </p>
            {error && <p className="error">{error}</p>}
            <form>
              <label htmlFor="email">Email address: </label>
              <br />
              {/* @ts-ignore*/}
              <input
                className="email-input"
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY as string}
                ref={captchaRef}
              />
              <br />
              <input
                className="delete-button"
                type="button"
                value="Delete Account"
                onClick={submitForm}
              />
            </form>
            <h3>FAQS</h3>
            <ul>
              <li>
                <strong>Why do you need my email address?</strong>
                <br />
                <p>
                  We only require your email address for the purposes of logging
                  into our Prediction League section and for contacting prize
                  winners. We strive to minimize the data we collect and only
                  use it for necessary functionalities.
                </p>
              </li>
              <li>
                <strong>
                  How long does it take to process my account deletion request?
                </strong>
                <br />
                <p>
                  We aim to respond to account deletion requests within 90 days.
                  This timeframe allows us to verify the authenticity of the
                  request and ensure compliance with our security measures.
                </p>
              </li>
              <li>
                <strong>
                  Why does it take so long to confirm my account deletion
                  request?
                </strong>
                <br />
                <p>
                  To maintain the integrity of our Prediction League and prevent
                  fraudulent activities, we conduct thorough verification
                  processes for account deletion requests. This ensures that
                  only legitimate users have their data removed from our
                  systems.
                </p>
              </li>
              <li>
                <strong>
                  What happens to my Prediction League data after my account is
                  deleted?
                </strong>
                <br />
                <p>
                  Once your account deletion request is confirmed, all your
                  Prediction League data will be permanently erased from our
                  systems.
                </p>
              </li>
              <li>
                <strong>
                  If I decide to sign up again, will my previous data be
                  restored?
                </strong>
                <br />
                <p>
                  No, if you choose to sign up again after deleting your
                  account, none of your previous data will be retained. Each
                  account is treated as a new entity, and previous data is not
                  linked to subsequent accounts.
                </p>
              </li>
              <li>
                <strong>
                  What if I signed up anonymously through Apple sign-in?
                </strong>
                <br />
                <p>
                  If you registered anonymously using Apple sign-in, you can
                  delete your account by accessing the settings within the app.
                  Once you initiate the deletion process, we can identify your
                  account and proceed with the deletion request accordingly.
                </p>
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
