// Think we change this to config

export const config = {
  arsenalnews: {
    name: "Arsenal News",
    primary: "#CA0007",
    primaryGradient: "#DB0007",
    podcast: 0xca0007,
    color: "#FFFFFF",
  },
  chelseanews: {
    name: "KTBFFH",
    primary: "#082e6f",
    primaryGradient: "#060a6a",
    podcast: 0x082e6f,
    color: "#FFFFFF",
  },
  evertonnews: {
    name: "Everton News",
    primary: "#00369C",
    primaryGradient: "#021E55",
    podcast: 0x00369c,
    color: "#FFFFFF",
  },
  forestnews: {
    name: "Forest News",
    primary: "#da291c",
    primaryGradient: "#881810",
    podcast: 0xda291c,
    color: "#FFFFFF",
  },
  leedsnews: {
    name: "Leeds News",
    primary: "#005B9E",
    primaryGradient: "#1D4189",
    podcast: 0x005b9e,
    headerBackground: "#FFFFFF",
    headerHighlight: "#005B9E",
    color: "#FFFFFF",
  },
  lfcnews: {
    name: "LFC News",
    primary: "#830003",
    primaryGradient: "#730003",
    podcast: 0x830003,
    color: "#FFFFFF",
  },
  ltfcnews: {
    name: "LTFC News",
    primary: "#f26722",
    primaryGradient: "#f25518",
    podcast: 0xf26722,
    color: "#FFFFFF",
  },
  mcfcnews: {
    name: "MCFC News",
    primary: "#5CBFEB",
    primaryGradient: "#2D3F57",
    podcast: 0x5cbfeb,
    color: "#FFFFFF",
  },
  mufcnews: {
    name: "MUFC News",
    primary: "#B00202",
    primaryGradient: "#D40B0B",
    podcast: 0xb00202,
    color: "#FFFFFF",
  },
  nufcnews: {
    name: "NUFC News",
    primary: "#000000",
    primaryGradient: "#404040",
    podcast: 0x000000,
    color: "#FFFFFF",
  },
  palacenews: {
    name: "Palace News",
    primary: "#D81E05",
    primaryGradient: "#0054A4",
    podcast: 0xd81e05,
    color: "#FFFFFF",
  },
  spursnews: {
    name: "Spurs News",
    primary: "#081351",
    primaryGradient: "#071130",
    podcast: 0x081351,
    color: "#FFFFFF",
  },
  villanews: {
    name: "Villa News",
    primary: "#56002B",
    primaryGradient: "#660033",
    podcast: 0x56002b,
    color: "#FFFFFF",
  },
  whunews: {
    name: "WHU News",
    primary: "#60223B",
    primaryGradient: "#6F2F3F",
    podcast: 0x60223b,
    color: "#FFFFFF",
  },
};
